import Helmet from "react-helmet"
import React from "react"
import logo from "../../content/assets/covid-trace-logo-nv.svg"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const appPath = `${__PATH_PREFIX__}/app`
  let header

  if (location.pathname === rootPath || location.pathname === appPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        ></Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={location.pathname.startsWith("/blog/") ? "/blog" : "/"}
        >
          <img alt="COVID Trace logo" src={logo} width={35} /> {title}
          <style jsx>{`
            img {
              vertical-align: text-bottom;
              margin: 0;
              border-radius: 7px;
            }
          `}</style>
        </Link>
      </h3>
    )
  }

  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.13.0/css/all.css"
          integrity="sha384-IIED/eyOkM6ihtOiQsX2zizxFBphgnv1zbe1bKA+njdFzkr6cDNy16jfIKWu4FNH"
          crossorigin="anonymous"
        />
      </Helmet>
      <header>{header}</header>
      <main>{children}</main>
      <center>
        <footer>
          <p>App Store is a registered trademarks of Apple Inc.</p>
          <p>
            Google Play and the Google Play logo are trademarks of Google LLC.
          </p>
        </footer>
      </center>
      <style jsx>{`
        div {
          margin-left: auto;
          margin-right: auto;
          max-width: ${rhythm(30)};
          padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
        }

        img {
          margin: 0 5px 10px;
        }

        img:first-of-type {
          border-radius: 6px;
        }

        p {
          margin: 0;
        }

        .soon {
          font-size: smaller;
        }

        footer {
          margin-top: 100px;
          font-size: smaller;
          color: #666;
        }
      `}</style>
    </div>
  )
}

export default Layout
